import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Content from "../components/common/Content";
import MarkdownContent from "../components/common/MarkdownContent";
import Hero from "../components/common/Hero";

class PageTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Hero title={post.frontmatter.title} />
        <Content>
          <MarkdownContent html={post.html} />
        </Content>
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
